import React from "react"
import SEO from "../components/seo"

import styled from "styled-components"
import { Navbar } from "../components/Navbar"
import { QA } from "../components/QA"
import { Text } from "../components/styled/Text"
import { Title } from "../components/styled/Title"
import { SecondaryTitle } from "../components/styled/SecondaryTitle"
import Layout from "../components/layout"

const Body = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 0 24px;
`

const OmOss = () => (
  <Layout>
    <SEO title="Vanlige spørsmål om Kjør" />
    <Body>
      <Title>Om oss</Title>
      <Text>Vi er en liten nyoppstartet grunderbedrift i Fredrikstad.</Text>

      <Text>
        Ideen om vår app kom til ved at jeg observerte at mange sjåfører kjørte
        og tastet på mobilen samtidig. Appen vår låser mobilens tastefunksjon,
        slik at du som kjører utelukkende kan konsentrere deg om å kjøre.
      </Text>

      <Text>Vi tror og håper at vår app kan redde liv i trafikken!</Text>
    </Body>
  </Layout>
)

export default OmOss
