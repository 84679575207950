import React from "react"
import styled from "styled-components"
import { Text } from "./styled/Text"
import { SmallTitle } from "./styled/SmallTitle"

const Wrapper = styled.div``

export const QA = ({ title, text, children }) => {
  return (
    <Wrapper>
      <SmallTitle>{title}</SmallTitle>
      {text ? text.map(item => <Text>{item}</Text>) : children}
    </Wrapper>
  )
}
